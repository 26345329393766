<template>
  <div class="privacypolicy">
    <div class="content">
      <div class="heade">
        <p>隐私政策</p>
      </div>
      <div class="con">
        <i class="width-index" />提示条款 <br />
        <i
          class="width-index"
        />我们深知个人信息对您的重要性，我们将按照法律法规要求，采取相应安全保护措施，尽力保护您的个人信息。您在使用我们的产品与/或服务时，我们可能会收集和使用您的相关信息。我们希望通过《云楚新能源隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。
        <br />
        <i class="width-index" />本政策将帮助您了解以下内容： <br />
        <i class="width-index" />一、我们如何收集和使用您的个人信息 <br />
        <i class="width-index" />二、我们如何使用 Cookie 和同类技术 <br />
        <i class="width-index" />三、我们如何共享、转让、公开披露您的个人信息
        <br />
        <i class="width-index" />四、我们如何保护和保存您的个人信息 <br />
        <i class="width-index" />五、您如何管理个人信息 <br />
        <i class="width-index" />六、我们如何处理未成年人的个人信息 <br />
        <i
          class="width-index"
        />本隐私政策与您所使用的云楚新能源商城服务以及该服务所包括的各种业务功能（以下统称“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。您使用或在我们更新本隐私政策后（我们会及时提示您更新的情况）继续使用我们的产品与/或服务，即意味着您同意本隐私政策(含更新版本)内容，并且同意我们按照本隐私政策收集、使用、保存和共享您的相关信息。
        <br />
        <i
          class="width-index"
        />如对本隐私政策或相关事宜有任何问题，请直接联系云楚新能源客户，客户服务电话：0871-65353806。
        <br />
        <i class="width-index" />一、我们如何收集和使用您的个人信息 <br />
        <i
          class="width-index"
        />个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。根据
        GB/T35273《个人信息安全规范》，个人信息包括：个人基本资料（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）；个人身份信息（包括身份证、军官证、护照、驾驶证等）；面部特征；网络身份标识信息（包括系统账号、IP
        地址、邮箱地址及与前述有关的密码、口令、口令保护答案）；个人财产信息（包括交易和消费记录、以及优惠券、唯品币、零钱、唯品花等虚拟财产信息）；通讯录；个人上网记录（包括网站浏览记录、软件使用记录、点击记录）；个人常用设备信息（包括硬件型号、设备
        MAC 地址、操作系统类型、移动应用列表、唯一设备识别码（如IMEI/android
        ID/IDFA/OPENUDID/GUID、SIM 卡IMSI
        信息等在内的描述个人常用设备基本情况的信息）；个人位置信息（包括行程信息、精准定位信息、住宿信息、经纬度等）；
        <br />
        <i
          class="width-index"
        />个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心
        健康受到损害或歧视性待遇等的个人信息。根据
        GB/T35273《个人信息安全规范》，个人敏感信息包括：
        个人财产信息（包括交易和消费记录、以及优惠券、唯品币、零钱、唯品花等虚拟财产信息）；面部识别特征；个人身份信息（包括身份证、军官证、护照、驾驶证等）；网络身份识别信息（包括账户名、账户昵称、邮箱地址及与前述有关的密码与密码保护问题和答案）；其他信息（包括通讯录、个人电话号码、手机号码、行程信息、网页浏览记录、住宿信息、精准定位信息）。
        <br />
        <i class="width-index" />我们仅会出于以下目的，收集和使用您的个人信息：
        <br />
        <i class="width-index" />（一）您授权我们收集和使用您个人信息的情形
        <br />
        <i
          class="width-index"
        />我们的产品与/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能、改进我们的产品与/或服务所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：
        <br />
        <i class="width-index" />1、实现网上购物和交易安全所必需的功能 <br />
        <i class="width-index" />（1）帮助您成为我们的会员 <br />
        <i
          class="width-index"
        />为成为我们的会员，以便我们为您提供会员服务，您需要提供手机号码、电子邮箱地址，并创建用户名和密码。如果您仅需使用浏览、搜索等基本服务，您不需要注册成为我们的会员及提供上述信息。
        <br />
        <i
          class="width-index"
        />在注册会员过程中，如果您提供以下额外信息补全个人资料，将有助于我们给您提供如会员生日特权等更个性化的会员服务：您的真实姓名、性别、出生年月日、居住地、您本人的真实头像。但如果您不提供这些信息，将会影响到您使用个性化的会员服务，但不会影响使用云楚新能源产品或服务的基本浏览、搜索、购买功能。
        <br />
        <i class="width-index" />（2）为您展示和推送商品和服务 <br />
        <i
          class="width-index"
        />为了改善我们的产品或服务，向您提供个性化的页面展示、快速的搜索结果以及判断账户安全状态、提供安全的交易服务，我们会收集关于您使用的我们的产品或服务以及使用方式的信息并将这些信息进行关联。这些信息包括：
        <br />
        <i
          class="width-index"
        />设备信息：我们会根据您在软件安装及使用中授予的具体权限，接收并记录您所使用的设备相关信息（例如设备名称、设备型号、操作系统和应用程序版本、语言设置、移动应用列表、唯一设备标识符、运营商网络类型等软硬件特征信息）、设备所在位置相关信息（例如
        IP 地址、GPS 位置以及能够提供相关信息的WLAN 接入点、蓝牙和基站等信息）。
        <br />
        <i
          class="width-index"
        />日志信息：当您使用我们的客户端或网站提供的产品或服务时，我们会自动收集您对我们服务的详
        细使用情况，作为有关网络日志保存。例如您的搜索内容、浏览器类型、浏览记录、订单信息、IP
        地址、访问日期和时间及您访问的网页记录等。 <br />
        <i
          class="width-index"
        />请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将该类个人信息做匿名化、去标识化处理。
        <br />
        <i
          class="width-index"
        />如果您不想接受我们给您发送的商业广告，您可随时通过相应产品退订功能取消。
        <br />
        <i class="width-index" />（3）下单 <br />
        <i
          class="width-index"
        />当您准备对您购物车/购物袋内的商品进行结算时，云楚新能源系统会生成您购买该商品的订单。您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、您应支付的货款金额及支付方式；您可以另外填写收货人的固定电话、邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。
        <br />
        <i
          class="width-index"
        />上述所有信息构成您的“订单信息”，我们将使用您的订单信息来进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务；我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。
        <br />
        <i class="width-index" />（4）支付功能 <br />
        <i
          class="width-index"
        />在您下单后，您可以选择与云楚新能源合作的第三方支付机构（包括支付宝支付、微信支付及银联、网联等支付通道，
        以下称“支付机构”）所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。
        <br />
        <i class="width-index" />（5）交付产品或服务功能 <br />
        <i
          class="width-index"
        />为便于向您交付商品或服务，您需提供收货人姓名、收货地址、邮政编码、收货人联系电话。如果我们委托第三方向您交付时，您同意将上述信息共享给第三方。如果您拒绝提供此类信息，我们将无法完成相关交付服务。
        <br />
        <i
          class="width-index"
        />您可以通过云楚新能源为其他人订购商品或服务，您需要提供该实际订购人的前述个人信息。向云楚新能源提供该实际订购人的前述个人信息之前，您需确保您已经取得其授权同意。
        <br />
        <i class="width-index" />（6）客服与售后功能 <br />
        <i
          class="width-index"
        />我们的电话客服和售后功能会使用您的账号信息和订单信息。 <br />
        <i
          class="width-index"
        />为保证您的账号安全，我们的呼叫中心客服和在线客服会使用您的账号信息与您核验您的身份。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。
        <br />
        <i class="width-index" />2、改进我们的产品与/或服务所必须的功能 <br />
        <i
          class="width-index"
        />我们可能会收集您的订单信息、浏览信息、您的购物习惯进行数据分析以形成用户画像，用来将您感兴趣的商品或服务信息展示给您；或在您搜索时向您展示您可能希望找到的商品。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上收集到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为
        您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。上述做法可能会对您的商品浏览页面展示、商品搜索页面排序、商品推荐页面展示产生影响。
        <br />
        <i class="width-index" />3、为您提供安全保障 <br />
        <i
          class="width-index"
        />为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或云楚新能源相关协议规则的情况，我们可能使用或整合您的会员信息、交易信息、设备信息、有关网络日志以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
        <br />
        <i
          class="width-index"
        />（二）您可选择是否授权我们收集和使用您的个人信息的情形 <br />
        <i
          class="width-index"
        />1、为向您提供更契合您需求的页面展示和搜索结果、了解产品适配性、识别账号异常状态，我们在以下附加功能中可能会收集您的个人信息，这些附加功能包括：
        <br />
        <i
          class="width-index"
        />（1）个性化推荐功能：我们会收集您的位置信息（我们仅收集您当时所处的地理位置，但不会将您
        各时段的位置信息进行结合以判断您的行踪轨迹）来判断您所处的地点，自动向您推荐您所在区域可以购买的商品或服务。
        <br />
        <i
          class="width-index"
        />（2）基于摄像头（相机）的附加功能：您可以使用这个附加功能完成视频拍摄、拍照、扫码功能。
        <br />
        <i
          class="width-index"
        />（3）基于图片上传的附加功能：您可以在云楚新能源上传您的照片来实现晒单及评价功能，我们会使用您所上传的照片来识别您需要购买的商品或使用包含您所上传图片的评价。
        <br />
        <i
          class="width-index"
        />2、上述附加功能需要您在您的设备中向我们开启您的地理位置（位置信息）、相机（摄像头）、相册（图片库）以及麦克风的访问权限。我们会通过弹窗方式提示告知您我们所需要获取的权限。如您是安卓用户，您可以通过移动设备-设置-权限管理逐项查看您上述权限的开启状态，并可以决定将这些权限随时的开启或关闭。如您是苹果用户，您可以通过移动设备-设置-隐私查看、开启或关闭上述权限。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
        <br />
        <i
          class="width-index"
        />（三）以下情形，我们收集、使用您的个人信息，无需征得您授权同意：
        <br />
        <i class="width-index" />1、与国家安全、国防安全有关的； <br />
        <i class="width-index" />2、与公共安全、公共卫生、重大公共利益有关的；
        <br />
        <i class="width-index" />3、与犯罪侦查、起诉、审判和判决执行等有关的；
        <br />
        <i
          class="width-index"
        />4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
        <br />
        <i
          class="width-index"
        />5、所收集的个人信息是个人信息主体自行向社会公众公开的； <br />
        <i
          class="width-index"
        />6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
        <br />
        <i class="width-index" />7、根据您的要求签订合同所必需的； <br />
        <i
          class="width-index"
        />8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
        <br />
        <i class="width-index" />9、为合法的新闻报道所必需的； <br />
        <i
          class="width-index"
        />10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，
        对结果中所包含的个人信息进行去标识化处理的； <br />
        <i class="width-index" />11、法律法规规定的其他情形。 <br />
        <i class="width-index" />（四）我们从第三方获得您个人信息的情形 <br />
        <i
          class="width-index"
        />我们可能从第三方获取您授权共享的账户信息（头像、昵称、手机号、邮箱号），并在您同意本隐私政策后将您的第三方账户与您的云楚新能源账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。
        <br />
        <i class="width-index" />（五）您个人信息使用的规则 <br />
        <i
          class="width-index"
        />1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。
        <br />
        <i
          class="width-index"
        />2、请您了解并同意，我们有权使用去标识化且无法识别您个人身份的个人信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
        <br />
        <i
          class="width-index"
        />3、请您注意，您在使用我们的产品与/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。
        <br />
        <i
          class="width-index"
        />4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，
        以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。
        <br />
        <i
          class="width-index"
        />5、当我们展示您的个人信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
        <br />
        <i
          class="width-index"
        />6、当我们要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会通过您主动做出勾选的形式事先征求您的同意。
        <br />
        <i class="width-index" />二、我们如何使用 Cookie 和同类技术 <br />
        <i class="width-index" />（一）Cookie <br />
        <i
          class="width-index"
        />1、为确保网站正常运转，为您获得更轻松的访问体验，向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上储存名为
        Cookie 的小数据文件，Cookie
        通常包含标识符、站点名称以及一些号码和字符。借助于
        Cookie,网站能够储存您的偏好或购物车/购物袋内的商品等数据。 <br />
        <i class="width-index" />2、您可根据自己的偏好管理或删除
        Cookie。您可以清除计算机上保存的所有 Cookie,大部分网络浏览器都设有阻止
        Cookie
        的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置，也可能导致部分功能无法正常使用。如需了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
        <br />
        <i class="width-index" />（二）网站信标和像素标签 <br />
        <i
          class="width-index"
        />我们会在网站上使用网站信标和像素标签等其他同类技术。例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。
        <br />
        <i class="width-index" />三、我们如何共享、转让、公开披露您的个人信息
        <br />
        <i class="width-index" />（一）共享 <br />
        <i
          class="width-index"
        />1、我们不会与云楚新能源以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
        <br />
        <i
          class="width-index"
        />（1）在获取明确同意的情况下共享；获得您的明确同意后，我们会与其他方共享您的个人信息。
        <br />
        <i
          class="width-index"
        />（2）在法定情形下的共享：我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。
        <br />
        <i
          class="width-index"
        />（3）在法律法规允许的范围内，为维护云楚新能源、云楚新能源的关联方或合作伙伴、您或其他云楚新能源用户或社会公众利益、财产或安全免遭损害而有必要提供；
        <br />
        <i
          class="width-index"
        />（4）只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
        <br />
        <i class="width-index" />（5）应您需求为您处理您与他人的纠纷或争议；
        <br />
        <i
          class="width-index"
        />（6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
        <br />
        <i class="width-index" />（7）基于学术研究而使用； <br />
        <i class="width-index" />（8）基于符合法律法规的社会公共利益而使用。
        <br />
        <i
          class="width-index"
        />2、我们可能会将您的个人信息与我们的关联方共享。但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
        <br />
        <i
          class="width-index"
        />3、与授权合作伙伴共享：仅为实现本隐私政策中声明的目的，我们的某些服务将由我们和授权合作伙伴
        共同提供。我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。例如，在您上网购买我们的产品时，我们必须与物流服务提供商共享您的个人信息才能安排送货，或者安排合作伙伴提供服务。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于与产品或服务无关的其他用途。
        目前，我们的合作伙伴包括以下类型： <br />
        <i
          class="width-index"
        />（1）商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。
        <br />
        <i
          class="width-index"
        />（2）第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其
        购买商品或服务的需求，并促使其可以完成后续的售后服务。 <br />
        <i
          class="width-index"
        />（3）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，
        而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。
        <br />
        <i
          class="width-index"
        />（4）合作金融机构或其他合作伙伴。您通过云楚新能源平台使用消费金融服务时（包括但不限于云楚新能源等金融产品），基于法律法规及风控要求，您需要提供姓名、身份证或其他身份证明、银行卡号及其绑定手机号、面部识别特征、常用地址、联系人信息，并将其共享给提供服务的第三方。
        <br />
        <i
          class="width-index"
        />4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，
        我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并对关键的第三方执行信息安全审计，以验证第三方的个人信息安全保护措施是否满足云楚新能源的相关要求。
        <br />
        <i
          class="width-index"
        />5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护云楚新能源、您或其他云楚新能源客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。
        不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。
        <br />
        <i class="width-index" />（二）转让 <br />
        <i
          class="width-index"
        />我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        <br />
        <i
          class="width-index"
        />1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；
        <br />
        <i
          class="width-index"
        />2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；
        <br />
        <i
          class="width-index"
        />3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；
        <br />
        <i
          class="width-index"
        />4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
        <br />
        <i class="width-index" />（三）公开披露
        我们仅会在以下情况下，才会公开披露您的个人信息： <br />
        <i
          class="width-index"
        />1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息；
        <br />
        <i
          class="width-index"
        />2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，
        以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。
        <br />
        <i class="width-index" />四、我们如何保护和保存您的个人信息 <br />
        <i class="width-index" />（一）个人信息保护的技术与措施 <br />
        <i class="width-index" />1、数据安全技术措施 <br />
        <i
          class="width-index"
        />我们已采取符合业界标准、合理可行的安全防护措施保护您提供的个人信息安全，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会使用加密技术、脱敏技术和匿名化技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；部署数据安全监控机制，尽力确保数据在数据生命周期（数据采集、存储、传输、使用、共享和销毁）中得到安全保障。
        <br />
        <i class="width-index" />2、其他安全措施 <br />
        <i
          class="width-index"
        />云楚新能源通过建立专门的管理制度、流程和组织以保障个人信息的存储和使用安全。云楚新能源通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。
        <br />
        <i
          class="width-index"
        />云楚新能源建立信息安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。
        <br />
        <i
          class="width-index"
        />加强安全意识。云楚新能源建立了信息安全培训体系，通过多种手段对全体员工进行信息安全意识培训，
        <br />
        <i
          class="width-index"
        />提高员工的信息安全意识以及隐私安全意识，保障会员个人信息安全。 <br />
        <i
          class="width-index"
        />3、我们仅允许有必要知晓这些信息的云楚新能源及云楚新能源关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与云楚新能源的合作关系。
        <br />
        <i
          class="width-index"
        />4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。
        <br />
        <i
          class="width-index"
        />5、我们大力强化对云楚新能源物流员工的数据安全培训和要求，提高物流员工保护用户隐私数据的意识；
        每年定期对云楚新能源物流大仓、前置仓、海淘仓库、配送站进行信息安全巡检，确保信息安全保障措施满足安全要求。
        <br />
        <i
          class="width-index"
        />6、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。
        <br />
        <i
          class="width-index"
        />7、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
        <br />
        <i
          class="width-index"
        />8、安全应急响应。当您在云楚新能源网站与第三方进行网上商品或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。
        针对个人信息泄露、毁损、丢失等安全风险，云楚新能源建立了安全应急响应机制和应急响应团队，从制度层面规范安全事件处置的要求和流程。对安全事件实施分类分级，针对不同事件启动有针对性的安全应急预案，进行事件检测、抑制、根除、恢复，制定补救措施，联合相关部门进行溯源和打击。
        <br />
        <i
          class="width-index"
        />9、在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动向监管部门上报个人信息安全事件的处置情况。
        <br />
        <i
          class="width-index"
        />10、如果您对我们的个人信息保护有任何疑问，可通过本政策最下方约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本政策最下方【如何联系我们】约定的联系方式联络我们，以便我们采取相应措施。
        <br />
        <i class="width-index" />（二）个人信息的保存 <br />
        <i
          class="width-index"
        />1、您的个人信息将被存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。
        <br />
        <i
          class="width-index"
        />2、在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，
        同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。
        <br />
        <i
          class="width-index"
        />3、请您注意，当您成功申请注销云楚新能源账户后，我们将对您的个人信息进行删除或匿名化处理。
        <br />
        <i
          class="width-index"
        />4、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。
        <br />
        <i class="width-index" />五、您如何管理您的个人信息
        您可以通过以下方式访问及管理您的个人信息： <br />
        <i class="width-index" />1、访问和更正您的个人信息 <br />
        <i
          class="width-index"
        />您有权随时访问和更正您的个人信息，法律法规规定的例外情况除外，您可以通过以下方式访问和更正您的个人信息：
        <br />
        <i
          class="width-index"
        />（1）移动端具体路径：账户名称、个人资料信息：“我的”—左上角点击人形头像—点击云楚新能源会员头像；“我的”页面可以直接设置个人资料、进行实名认证、账户名称、收货地址；修改登录密码、支付密码及绑定手机。
        <br />
        <i
          class="width-index"
        />（2）订单信息：移动端具体路径为，“我的”-点击左上角“订单”-“我的订单”。
        <br />
        <i
          class="width-index"
        />（3）档案信息：移动端您可以在“我的”直接进项查找。 <br />
        <i
          class="width-index"
        />（4）您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。
        <br />
        <i
          class="width-index"
        />（5）无法访问和更正的个人信息：除上述列明的信息外，您的部分个人信息我们还无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用
        附加功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。
        <br />
        <i class="width-index" /> 2、删除您的个人信息 <br />
        <i
          class="width-index"
        />您在我们的产品与/或服务页面中可以直接清除或删除的信息，包括订单信息、收货地址信息；
        <br />
        <i class="width-index" />
        在以下情形中，您可以向我们提出删除个人信息的请求：
        <br />
        <i class="width-index" />（1）如果我们处理个人信息的行为违反法律法规；
        <br />
        <i
          class="width-index"
        />（2）如果我们收集、使用您的个人信息，却未征得您的同意； <br />
        <i
          class="width-index"
        />（3）如果我们处理个人信息的行为违反了与您的约定； <br />
        <i class="width-index" />（4）如果您注销了云楚新能源账户； <br />
        <i class="width-index" />（5）如果我们终止服务及运营。 <br />
        <i class="width-index" />
        若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
        <br />
        <i class="width-index" />3、改变您授权同意的范围或撤回您的授权 <br />
        <i class="width-index" />您可以在移动设备的“设置”中关闭对云楚新能源
        APP的功能授权，包括位置功能、电话功能、相机功能、短信功能、通讯录功能、麦克风功能，改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。
        <br />
        <i
          class="width-index"
        />请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。
        <br />
        <i
          class="width-index"
        />4、如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消：
        <br />
        <i class="width-index" />（1）您可通过 PC
        端账户设置页面的邮件订阅菜单中取消邮件订阅的促销信息。 <br />
        <i
          class="width-index"
        />（2）您可以随时回复“TD”来取消我们给您发送的手机促销短信。 <br />
        <i
          class="width-index"
        />（3）为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性、疾病等相关敏感内容的促销或商品信息给您。
        <br />
        <i class="width-index" />5、个人信息主体获取个人信息副本 <br />
        <i
          class="width-index"
        />您有权获取您的个人信息副本，您可以通过以下方式自行操作： <br />
        <i class="width-index" />进入个人中心，点击“我的”页面获取和人信息。
        <br />
        <i class="width-index" />6、响应您的上述请求 <br />
        <i
          class="width-index"
        />如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与/或服务时所产生的其他个人信息，或您认为云楚新能源存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本协议下方的方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，我们将在收到您反馈并验证您的身份后的
        15天内答复您的请求。对于您合理的请求，我们原则上不收取费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
        <br />
        <i
          class="width-index"
        />在以下情形中，按照法律法规要求，我们将无法响应您的请求： <br />
        <i class="width-index" />（1）与国家安全、国防安全有关的； <br />
        <i class="width-index" />（2）与公共安全、公共卫生、重大公共利益有关的；
        <br />
        <i class="width-index" />（3）与犯罪侦查、起诉和审判等有关的； <br />
        <i class="width-index" />（4）有充分证据表明您存在主观恶意或滥用权利的；
        <br />
        <i
          class="width-index"
        />（5）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
        <br />
        <i class="width-index" />六、我们如何处理未成年人的个人信息 <br />
        <i
          class="width-index"
        />如果没有父母或监护人的同意，未成年人不得创建自己的用户账户。如您为未成年人的，建议您请您的父母或监护人仔细阅读本隐私权政策，并在征得您的父母或监护人同意的前提下使用我们的服务或向我们提供信息。
        <br />
        <i
          class="width-index"
        />对于经父母或监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
        <br />
        <i class="width-index" />七、您的个人信息如何在全球范围转移 <br />
        <i
          class="width-index"
        />原则上，我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中华人民共和国境内，
        <br />
        <i class="width-index" />以下情形除外： <br />
        <i class="width-index" />（1）适用的法律有明确规定； <br />
        <i class="width-index" />（2）获得您的明确授权； <br />
        <i class="width-index" />（3）您通过互联网进行跨境交易等个人主动行为。
        <br />
        <i
          class="width-index"
        />针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。八、通知和修订
        <br />
        <i
          class="width-index"
        />1、为给您提供更好的服务以及随着云楚新能源业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在云楚新能源网站、云楚新能源移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问云楚新能源以便及时了解最新的隐私政策。
        <br />
        <i
          class="width-index"
        />2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。
        <br />
        <i class="width-index" />本政策所指的重大变更包括但不限于： <br />
        <i
          class="width-index"
        />（1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
        <br />
        <i
          class="width-index"
        />（2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
        <br />
        <i
          class="width-index"
        />（3）个人信息共享、转让或公开披露的主要对象发生变化； <br />
        <i
          class="width-index"
        />（4）您参与个人信息处理方面的权利及其行使方式发生重大变化； <br />
        <i
          class="width-index"
        />（5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
        <br />
        <i class="width-index" />（6）个人信息安全影响评估报告表明存在高风险时。
        <br />
        <i class="width-index" />3、我们还会将本策略的旧版本存档，供您查阅。
        <br />
        <i class="width-index" />九、如何联系我们 <br />
        <i
          class="width-index"
        />1、如您对本隐私政策或您个人信息的相关事宜有任何问题、意见或建议，请联系云楚新能源客户服务部，客户服务部电话：0871-65353806。
        <br />
        <i
          class="width-index"
        />2、云楚新能源平台运营者为深圳云楚新能源电子商务有限公司，注册地址为深圳市福田区莲花街道紫荆社区商报东路英龙商务大厦。
        <br />
        <i
          class="width-index"
        />3、我们设立了个人信息保护专职部门，您可以通过发送邮件至postmaster@yunpinec.com
        的方式与其联系。 <br />
        <i
          class="width-index"
        />4、一般情况下，我们将在十五天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报，或通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
        <br />
        <i class="width-index" />注：本《云楚新能源隐私政策》版本更新日期为 2020 年 12
        月 28 日，将于 2020 年 12 月 30 日正式生效。
        <br />
        附件一：《云楚新能源APP涉及个人信息传输的SDK情况表》 安卓端：
        <Table
          :columns="columns"
          :data-source="data"
          :scroll="{ x: '690%' }"
          :pagination="false"
        >
          <a slot="link" slot-scope="text" :href="text">{{ text }}</a>
        </Table>
      </div>
      <div class="foote">
        <p @click="Login">同意并返回</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Table } from "ant-design-vue";
const columns = [
  {
    dataIndex: "no",
    key: "no",
    title: "序号",
    ellipsis: true,
  },
  {
    title: "SDK名称",
    dataIndex: "sdk",
    key: "age",
    align: "center",
  },
  {
    title: "应用场景描述",
    dataIndex: "desc",
    key: "desc",

    align: "center",
  },
  {
    title: "个人信息字段",
    key: "info",
    dataIndex: "info",
    align: "center",
  },
  {
    title: "第三方机构名称",
    key: "name",

    dataIndex: "name",
    align: "center",
  },
  {
    title: "隐私协议链接",
    dataIndex: "link",
    scopedSlots: { customRender: "link" },
    ellipsis: true,
    align: "center",
  },
];

const data = [
  {
    no: 1,
    sdk: "com.tencent.mm.opensdk:wechat-sdk-android-without-mta:5.1.4",
    desc: "微信支付",
    info: "设备标识符",
    name: "	深圳市腾讯计算机系统有限公司",
    link: "http://www.tencent.com/zh-cn/privacy-policy.html",
  },
  {
    no: 2,
    sdk: "alipush",
    desc: "阿里推送",
    info: "设备标识符、IMEI、VAID、AAID、MID、设备型号、网络类型、设备存储",
    name: "	阿里巴巴集团控股有限公司",
    link: "http://terms.aliyun.com/legal-agreement/terms/suit_bu1_ali_cloud/suit_bu1_ali_cloud201902141711_54837.html?spm=a2c4g.11186623.J_9220772140.83.34b67c46gd9rbC",
  },
];
export default {
  data() {
    return {
      data,
      columns,
    };
  },
  components: {
    Table,
  },
  mounted() {
    // document.documentElement.style.fontSize = "16px";
  },
  methods: {
    // 跳转到登录页面
    Login() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.ant-table-body {
  a {
    color: #8cbbfa;
  }

  tr {
    height: 1.875rem;
  }
}

.privacypolicy {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 0.3rem;
  // background-color: #f0eded;

  .heade {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    // padding: 0 1rem;
    line-height: 1rem;
    z-index: 99;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    p {
      text-align: center;
      color: #bc051d;
      font-size: 0.5rem;
    }
  }
  .con {
    margin-top: 0.6rem;
    line-height: 0.5rem;
    padding: 0.5rem 0.4rem 2rem;
  }
  .foote {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #eeeeee;
    width: 100%;
    line-height: 40px;
    padding: 0 20px;
    color: #333333;
  }
}
.width-index {
  display: inline-block;
  width: 0.3rem;
}
</style>